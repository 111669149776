@use './config/' as *;
@forward "./main-scss/";
.waf-season-poll {
    padding: var(--space-1);
    .waf-body {
        @extend %p-4-2;
    }
    .season-poll {
        &-label {
            border-top: .1rem solid clr(mid-grey);
            width: 100%;
            @extend %pt-1;
        }
        &-wrap {
            &:not(:first-child) {
                .season-poll-head {
                    border-top: .1rem solid clr(mid-grey);
                    @extend %mt-6;
                    @extend %pt-3;
                }
            }
            &-goal {
                .coming-soon {
                    padding-left: 0;
                    width: auto;
                }
                .season-poll-item-final {
                    .coming-soon {
                        width: 100%;
                    }
                }
            }
        }
        &-head {
            border-bottom: .1rem solid clr(mid-grey);
            @extend %mb-3;
            @extend %pb-3;
        }
        &-title {
            @extend %font-18-pb;
            @extend %secondary-light;
        }
        &-body {
            display: grid;
            @extend %gap-3;
        }
        &-item {
            border-bottom: .1rem solid clr(mid-grey);
            // display: grid;
            // grid-template-columns: 2rem auto 2rem;
            // align-items: center;
            @extend %flex-n-c;
            @extend %half-radius;
            @extend %p-3;
            @extend %white-bg;
            @extend %gap-2;
            @extend %secondary-dark;
            @extend %w-100;
            @extend %flex-wrap;
            &-disabled {
                opacity: 0.7;
                flex-wrap:nowrap;
            }
            .coming-soon {
                @extend %pl-6;
                .text {
                    @extend %font-8-pb;
                }
            }
            .icon-goal {
                width: 2rem;
                height: 2rem;
                color: inherit;
                @include background("svg/football.svg", center/contain no-repeat);
            }
            .icon-right {
                width: 2rem;
                height: 2rem;
                color: inherit;
                margin-left: auto;
                &::before {
                    color: inherit;
                    @include icon(chevron-right, 16);
                }
            }
            &-final {
                border-bottom: 1px solid clr(accent);
                .icon-goal {
                    @include background("svg/football-gold.svg", center/contain no-repeat);
                }
            }
            .text {
                @extend %font-14-pm;
            }
        }
    }
    .label {
        width: max-content;
        display: inline-block;
        transition: 300ms;
        @extend %secondary-dark-bg-1;
        @extend %font-12-pb;
        @extend %rounded-radius;
        @extend %secondary-dark;
        @extend %px-4;
        @extend %mt-2;
        &:hover {
            @extend %primary-bg;
            @extend %white;
        }
        & + & {
            @extend %ml-2;
        }
    }
    .coming-soon {
        @extend %w-100;
        .text {
            background: linear-gradient(116.22deg, clr(secondary-dark) 22.36%, clr(primary) 153.86%);
            width: max-content;
            clip-path: polygon(100% 0, calc(100% - 1rem) 50%, 100% 100%, 0 100%, 0 0);
            @extend %white;
            @extend %py-1;
            @extend %font-8;
            @extend %pr-5;
            @extend %pl-2;
            @extend %d-block;
            @extend %uppercase;
            @extend %font-8-pb;
        }
    }
}
@include mq(col-md) {
    .waf-season-poll {
        .season-poll {
            &-wrap:not(:first-child) .season-poll-head {
                margin-top: 5rem
            }
            &-head {
                display: flex;
                gap: var(--space-2);
                align-items: center;
            }
            &-item {
                .coming-soon {
                    padding-left: 0;
                    .text {
                        font-size: 1rem;
                    }
                }
                .text {
                    font-size: 2rem
                }
            }
        }
        .coming-soon {
            width: auto;
            padding-left: 0;
            font-size: 1rem;
        }
        .label {
            font-size: 1.2rem;
        }
    }
}